import styled from 'styled-components';
import { type FadeProps, Reveal, type RevealProps } from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';

type Direction = FadeProps['direction'] | 'none';

export function Fade(props: RevealProps & { direction?: Direction; subtle?: boolean }) {
    return (
        <Reveal
            {...props}
            duration={props.duration ?? 1000}
            triggerOnce={props.triggerOnce ?? true}
            keyframes={fadeAnimation(props.direction ?? 'none', props.subtle ?? true)}
        />
    );
}

function getTranslateX(direction: Direction, subtle: boolean) {
    switch (direction) {
        case 'right':
        case 'top-right':
        case 'bottom-right': {
            return subtle ? '-50%' : '-100%';
        }
        case 'left':
        case 'top-left':
        case 'bottom-left': {
            return subtle ? '50%' : '100%';
        }
        default: {
            return '0';
        }
    }
}

function getTranslateY(direction: Direction, subtle: boolean) {
    switch (direction) {
        case 'down':
        case 'bottom-left':
        case 'bottom-right': {
            return subtle ? '-50%' : '-100%';
        }
        case 'up':
        case 'top-left':
        case 'top-right': {
            return subtle ? '50%' : '100%';
        }
        default: {
            return '0';
        }
    }
}

function fadeAnimation(direction: Direction, subtle: boolean) {
    return keyframes`
        from {
            opacity: 0;
            transform: translate3d(
                ${getTranslateX(direction, subtle)},
                ${getTranslateY(direction, subtle)},
                0
            );
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    `;
}

export const OffsetFade = styled(Fade)<{ offset?: number | string }>`
    margin-top: -${(p) => p.offset ?? 200}px;
    margin-bottom: ${(p) => p.offset ?? 200}px;

    > * {
        transform: translateY(${(p) => p.offset ?? 200}px);
    }
`;
