import { css } from 'styled-components';

export const reset = css`
    /***
        The new CSS reset - version 1.5.1 (last updated 1.3.2022)
        GitHub page: https://github.com/elad2412/the-new-css-reset
    ***/

    /*
        Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
        - The "symbol *" part is to solve Firefox SVG sprite bug
    */
    *:where(:not(iframe, canvas, img, svg, video):not(svg *, symbol *)) {
        all: unset;
        display: revert;
    }

    body,
    .docs-story {
        background-color: ${(p) => p.theme.color.gray10};
    }

    /* Preferred box-sizing value */
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    /* Reapply the pointer cursor for anchor tags */
    a,
    button {
        cursor: revert;
    }

    /* Remove list styles (bullets/numbers) */
    ol,
    ul,
    menu {
        list-style: none;
    }

    /* For images to not be able to exceed their container */
    img {
        max-width: 100%;
    }

    /* removes spacing between cells in tables */
    table {
        border-collapse: collapse;
    }

    /* revert the 'white-space' property for textarea elements on Safari */
    textarea {
        white-space: revert;
    }

    /* minimum style to allow to style meter element */
    meter {
        -webkit-appearance: revert;
        appearance: revert;
    }

    /* reset default text opacity of input placeholder */
    ::placeholder {
        color: unset;
    }

    /* fix the feature of 'hidden' attribute.
    display:revert; revert to element instead of attribute */
    :where([hidden]) {
        display: none;
    }

    /* revert for bug in Chromium browsers
    - fix for the content editable attribute will work properly. */
    :where([contenteditable]) {
        -moz-user-modify: read-write;
        -webkit-user-modify: read-write;
        overflow-wrap: break-word;
        -webkit-line-break: after-white-space;
    }

    /* apply back the draggable feature - exist only in Chromium and Safari */
    :where([draggable='true']) {
        -webkit-user-drag: element;
    }
`;
