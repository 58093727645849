import React, { type ButtonHTMLAttributes, useMemo } from 'react';
import styled, { css } from 'styled-components';
import type { Link, LinkProps } from 'react-router-dom';

import { ProgressCirlce } from './progress-circle';

type As = 'button' | 'a' | 'link';
export type ButtonAsProps<as extends As> = Props & as extends 'link'
    ? LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
    : as extends 'a'
    ? React.AnchorHTMLAttributes<HTMLAnchorElement>
    : React.ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonAs<as extends As> = (props: ButtonAsProps<as>) => JSX.Element;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    asElement?: Omit<As, 'link'> | typeof Link;
    // The title text for the button
    title?: string;
    // If the button should show the loading icon
    loading?: boolean;
    // The font size of the button
    size?: number;
    // The variant of the button which changes the color and appearance
    variant?: 'primary' | 'secondary' | 'transparent';
}

export function Button({
    title,
    loading,
    children,
    size = 18,
    variant = 'primary',
    asElement,
    ...restProps
}: Props): JSX.Element {
    const FinalButton = useMemo(() => {
        if (variant === 'secondary') return SecondaryButton;
        if (variant === 'transparent') return TransparentButton;
        return PrimaryButton;
    }, [variant]);

    const TransparentBackground = useMemo(() => variant === 'transparent', [variant]);

    const disabled = useMemo(() => {
        if (loading) return true;
        if (restProps?.disabled) return true;
        return false;
    }, [loading, restProps?.disabled]);

    return (
        <FinalButton
            {...restProps}
            disabled={disabled}
            $size={size}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            as={asElement as string | React.ReactNode as any}
        >
            <Content $loading={loading}>
                {title}
                {children}
            </Content>
            {loading && (
                <Loading
                    size={size * 1.5}
                    strokeWidth={2}
                    infinite
                    backgroundColor='transparent'
                    color={TransparentBackground ? 'gray1' : 'gray10'}
                />
            )}
        </FinalButton>
    );
}

interface BaseProps {
    disabled?: boolean;
    $size: number;
}

const Base = css<BaseProps>`
    ${(p) => p.theme.flex.row({ fullWidth: false, justify: 'center' })}
    position: relative;
    cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
    user-select: none;
    outline: none;

    padding: ${(p) => p.$size * 0.75}px ${(p) => p.$size * 1.5}px;
    border-radius: ${(p) => p.$size * 0.5}px;

    > div {
        ${(p) => p.theme.text.Manrope.regular(p.$size, 'gray10', 'center')}
    }

    will-change: transform, background-color, border-color;
    transition: transform 0.2s linear, background-color 0.2s ease-out, border-color 0.2s ease-out;

    :hover:not(:disabled) {
        transform: scale3d(0.99, 0.99, 0.99);
    }

    :active:not(:disabled) {
        transform: scale3d(0.98, 0.98, 0.98);
        transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275), background-color 0.2s ease-out,
            border-color 0.2s ease-out;
    }

    :focus:not(:disabled) {
        box-shadow: 0 0 0 2.5pt ${(p) => p.theme.color.buttonPrimary}40;
    }
`;

const PrimaryButton = styled.button`
    ${Base}

    background-color: ${(p) => p.theme.color.buttonPrimary};

    :hover:not(:disabled) {
        background-color: ${(p) => p.theme.color.buttonPrimaryHover};
    }

    :active:not(:disabled) {
        background-color: ${(p) => p.theme.color.buttonPrimaryActive};
    }
`;

const SecondaryButton = styled.button`
    ${Base}

    background-color: ${(p) => p.theme.color.buttonSecondary};

    :hover:not(:disabled) {
        background-color: ${(p) => p.theme.color.buttonSecondaryHover};
    }

    :active:not(:disabled) {
        background-color: ${(p) => p.theme.color.buttonSecondaryActive};
    }
`;

const TransparentButton = styled.button`
    ${Base}

    > div {
        transition: color 0.2s ease-out;
        will-change: color;

        ${(p) => p.theme.text.Manrope.regular(p.$size, 'gray1', 'center')}
    }

    :hover:not(:disabled) {
        > div {
            color: ${(p) => p.theme.color.gray2};
        }
    }
`;

const Content = styled.div<{ $loading?: boolean }>`
    opacity: ${(p) => (p.$loading ? 0 : 1)};
    ${(p) => p.theme.flex.col({ align: 'center', justify: 'center' })}
`;

const Loading = styled(ProgressCirlce)`
    position: absolute;
`;
