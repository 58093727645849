import React, { useEffect, useMemo } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { getColorFunction } from './base/color';
import { themes } from './themes';
import { AppStyles } from './app';

import { useAppDispatch, useAppSelector } from '@/store';
import type { Theme, Themes } from '@/types';

interface Props {
    children: React.ReactNode;
}

/**
 * Adds the theme provider to the app and handles the theme updates based on the theme model.
 * @param param0 React children
 * @returns Wrapper with theme provider
 */
export const ThemeProvider = ({ children }: Props) => {
    const dispatch = useAppDispatch();
    const theme = useAppSelector((state) => state.theme);
    const rehydrated = useAppSelector((state) => state._persist.rehydrated);

    useEffect(() => {
        if (rehydrated) {
            dispatch.theme.update({});

            // If auto theme is selected, listen to system theme changes
            if (theme.appearance.selected === 'auto') {
                const listenerDarkMode = () => {
                    dispatch.theme.update({});
                };
                const matchDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
                // Update the theme on media query change
                matchDarkMode.addEventListener('change', listenerDarkMode, { passive: true });
                return () => {
                    matchDarkMode.removeEventListener('change', listenerDarkMode);
                };
            }
        }
    }, [rehydrated, dispatch.theme, theme.appearance.selected]);

    // Calculate the theme based on the selected appearance and the custom colors
    const calculatedTheme = useMemo(() => {
        const selectedTheme: Theme =
            themes[theme.theme ?? (Object.keys(themes)[0] as Themes)][theme.appearance.calculated];
        return {
            ...selectedTheme,
            color: getColorFunction({ ...selectedTheme.color.colors, ...theme.colors }),
        };
    }, [theme.appearance.calculated, theme.colors, theme.theme]);

    // TODO: bind $disableAnimations to AppStyles component

    return (
        <StyledThemeProvider theme={calculatedTheme}>
            <AppStyles />
            {children}
        </StyledThemeProvider>
    );
};
