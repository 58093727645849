import { $icon, type IconProps } from './_icon';

interface Props extends IconProps {
    direction: 'up' | 'down' | 'left' | 'right';
}

export function ArrowUpIcon(props: IconProps): JSX.Element {
    const [color, size] = $icon(props);

    return (
        <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8 15C8.27614 15 8.5 14.7761 8.5 14.5V2.70711L11.6464 5.85355C11.8417 6.04882 12.1583 6.04882 12.3536 5.85355C12.5488 5.65829 12.5488 5.34171 12.3536 5.14645L8.35355 1.14645C8.15829 0.951184 7.84171 0.951184 7.64645 1.14645L3.64645 5.14645C3.45118 5.34171 3.45118 5.65829 3.64645 5.85355C3.84171 6.04882 4.15829 6.04882 4.35355 5.85355L7.5 2.70711V14.5C7.5 14.7761 7.72386 15 8 15Z'
                fill={color}
            />
        </svg>
    );
}

export function ArrowDownIcon(props: IconProps): JSX.Element {
    const [color, size] = $icon(props);

    return (
        <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8 1C8.27614 1 8.5 1.22386 8.5 1.5V13.2929L11.6464 10.1464C11.8417 9.95118 12.1583 9.95118 12.3536 10.1464C12.5488 10.3417 12.5488 10.6583 12.3536 10.8536L8.35355 14.8536C8.15829 15.0488 7.84171 15.0488 7.64645 14.8536L3.64645 10.8536C3.45118 10.6583 3.45118 10.3417 3.64645 10.1464C3.84171 9.95118 4.15829 9.95118 4.35355 10.1464L7.5 13.2929V1.5C7.5 1.22386 7.72386 1 8 1Z'
                fill={color}
            />
        </svg>
    );
}

export function ArrowLeftIcon(props: IconProps): JSX.Element {
    const [color, size] = $icon(props);

    return (
        <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15 8C15 7.72386 14.7761 7.5 14.5 7.5L2.70711 7.5L5.85355 4.35355C6.04882 4.15829 6.04882 3.84171 5.85355 3.64645C5.65829 3.45118 5.34171 3.45118 5.14645 3.64645L1.14645 7.64645C0.951184 7.84171 0.951184 8.15829 1.14645 8.35355L5.14645 12.3536C5.34171 12.5488 5.65829 12.5488 5.85355 12.3536C6.04882 12.1583 6.04882 11.8417 5.85355 11.6464L2.70711 8.5H14.5C14.7761 8.5 15 8.27614 15 8Z'
                fill={color}
            />
        </svg>
    );
}

export function ArrowRightIcon(props: IconProps): JSX.Element {
    const [color, size] = $icon(props);

    return (
        <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M1 8C1 7.72386 1.22386 7.5 1.5 7.5L13.2929 7.5L10.1464 4.35355C9.95118 4.15829 9.95118 3.84171 10.1464 3.64645C10.3417 3.45118 10.6583 3.45118 10.8536 3.64645L14.8536 7.64645C15.0488 7.84171 15.0488 8.15829 14.8536 8.35355L10.8536 12.3536C10.6583 12.5488 10.3417 12.5488 10.1464 12.3536C9.95118 12.1583 9.95118 11.8417 10.1464 11.6464L13.2929 8.5H1.5C1.22386 8.5 1 8.27614 1 8Z'
                fill={color}
            />
        </svg>
    );
}

export function ArrowIcon({ direction, ...props }: Props): JSX.Element {
    switch (direction) {
        case 'up': {
            return <ArrowUpIcon {...props} />;
        }
        case 'down': {
            return <ArrowDownIcon {...props} />;
        }
        case 'left': {
            return <ArrowLeftIcon {...props} />;
        }
        case 'right': {
            return <ArrowRightIcon {...props} />;
        }
    }
}
