import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';

import '@/i18n';

import { App } from './app';
import { store } from './store';
import { ThemeProvider } from './theme';

smoothscroll.polyfill();

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <React.Suspense fallback={null}>
                <ThemeProvider>
                    <App />
                </ThemeProvider>
            </React.Suspense>
        </Provider>
    </React.StrictMode>,
);
