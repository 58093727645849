import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { EnvelopeIcon } from '@/icons/platform';
import { PhoneIcon } from '@/icons/platform/phone';

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <BackgroundImage src='assets/images/triangles.svg' />
            <Content>
                <Section>
                    <Logo src='/assets/images/logo-white.png' />
                    <CompanyDetails>
                        <CompanyDetailText>{t('common.footer.address.street')}</CompanyDetailText>
                        <CompanyDetailText>{t('common.footer.address.postal')}</CompanyDetailText>
                        <CompanyDetailText>{t('common.footer.address.country')}</CompanyDetailText>
                    </CompanyDetails>
                </Section>
                <Section>
                    <SectionTitle>{t('common.footer.links.title')}</SectionTitle>
                    <PageLink to='/#about-our-business'>{t('common.footer.links.about')}</PageLink>
                    <PageLink to='/#faq'>{t('common.footer.links.faq')}</PageLink>
                    <NativePageLink href='/assets/documents/algemene-voorwaarden-2022.pdf' download>
                        {t('common.footer.links.terms')}
                    </NativePageLink>
                    <PageLink to='/privacy'>{t('common.footer.links.privacy')}</PageLink>
                </Section>
                <Section>
                    <SectionTitle>{t('common.footer.contact.title')}</SectionTitle>
                    <Row>
                        <IconHolder>
                            <EnvelopeIcon size={24} color='gray10' />
                        </IconHolder>
                        <NativePageLink href='mailto:contact@vemabo.nl' $hasIcon>
                            contact@vemabo.nl
                        </NativePageLink>
                    </Row>
                    <Row>
                        <IconHolder>
                            <PhoneIcon size={24} color='gray10' />
                        </IconHolder>
                        <NativePageLink href='tel:+31634170685' $hasIcon>
                            +31 (0) 6 3417 0685
                        </NativePageLink>
                    </Row>
                    <Row>
                        <IconHolder>KvK</IconHolder>
                        <NativePageLink $hasIcon>85170704</NativePageLink>
                    </Row>
                    <Row>
                        <IconHolder>BTW</IconHolder>
                        <NativePageLink $hasIcon>NL863533772B01</NativePageLink>
                    </Row>
                </Section>
            </Content>
        </Container>
    );
};

const BackgroundImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.1;
    object-fit: cover;
    pointer-events: none;
`;

const Container = styled.div`
    position: relative;
    overflow: hidden;
    ${(p) => p.theme.flex.col({ align: 'center', justify: 'center' })}
    background-color: ${(p) => p.theme.color.blue};
    padding: 120px 0;
`;

const Content = styled.div`
    ${(p) => p.theme.flex.row({ align: 'flex-start', justify: 'flex-start' })}
    gap: 40px;
    width: 90%;
    max-width: 1900px;

    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
`;

const Section = styled.div`
    ${(p) => p.theme.flex.col({ align: 'flex-start', justify: 'flex-start' })}
    flex: 1;
    z-index: 1;

    @media screen and (max-width: 900px) {
        &:not(:first-child) {
            margin-top: 20px;
        }
    }
`;

const Logo = styled.img`
    width: 300px;

    @media screen and (max-width: 900px) {
        width: 90%;
        align-self: center;
    }
`;

const CompanyDetails = styled.div`
    margin-top: 40px;
    margin-left: 20px;

    @media screen and (max-width: 900px) {
        margin-left: 0;
    }
`;

const CompanyDetailText = styled.p`
    ${(p) => p.theme.text.Manrope.regular(18, 'gray9')}
    margin-bottom: 8px;

    @media screen and (max-width: 900px) {
        font-size: 16px;
    }
`;

const SectionTitle = styled.h3`
    ${(p) => p.theme.text.Manrope.medium(24, 'gray10')}
    margin-bottom: 40px;
`;

const Row = styled.div`
    ${(p) => p.theme.flex.row({ align: 'center', justify: 'flex-start' })}
    margin-bottom: 20px;
    height: 30px;
`;

const LinkStyle = css<{ $hasIcon?: boolean }>`
    ${(p) => p.theme.text.Manrope.regular(18, 'gray10')}
    margin-bottom: ${(p) => (p.$hasIcon ? 0 : 20)}px;
    text-decoration: none;
    cursor: pointer;
    height: 32px;
    line-height: 30px;

    will-change: color;
    transition: color 0.2s ease-out;

    :hover {
        color: ${(p) => p.theme.color.gray5};
    }

    @media screen and (max-width: 900px) {
        line-height: 30px;
        font-size: 16px;
    }
`;

const PageLink = styled(Link)`
    ${LinkStyle}
`;

const NativePageLink = styled.a`
    ${LinkStyle}
`;

const IconHolder = styled.div`
    ${(p) => p.theme.flex.col({ align: 'center', justify: 'center' })}
    ${(p) => p.theme.text.Manrope.regular(14, 'gray10')}
    width: 30px;
    height: 30px;
    margin-right: 20px;
`;
