import { createModel } from '@rematch/core';

import type { RootModel } from '.';

import type { colors } from '@/theme/base/color';
import type { Appearance, Themes } from '@/types';
import { themes } from '@/theme';

type Colors = Partial<typeof colors>;

interface ThemeState {
    // The active colors
    colors: Colors;
    // The active appearance
    appearance: {
        selected: Appearance;
        calculated: 'light' | 'dark';
    };
    theme: Themes;
}
type ThemeOptions = Partial<ThemeState>;

const INITIAL_STATE: ThemeState = {
    colors: {},
    appearance: {
        selected: 'auto',
        calculated: window.matchMedia?.('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
    },
    theme: Object.keys(themes)[0] as Themes,
};

/**
 * Function to get the appearance based on the provided string.
 * @param appearance String to convert to light or dark
 * @returns String containing light or dark
 */
function getAppearance(appearance: Appearance): 'light' | 'dark' {
    switch (appearance) {
        case 'dark': {
            return 'dark';
        }
        case 'auto': {
            try {
                if (window.matchMedia?.('(prefers-color-scheme: dark)').matches) return 'dark';
            } catch {}
        }
    }
    return 'light';
}

export const theme = createModel<RootModel>()({
    name: 'theme',
    state: INITIAL_STATE,
    reducers: {
        set(state, pl: ThemeOptions) {
            return { ...state, ...pl };
        },
    },
    effects: (dispatch) => ({
        /**
         * Function to reset the theme to the initial state.
         */
        reset(): void {
            dispatch.theme.update({ appearance: INITIAL_STATE.appearance.selected });
        },
        /**
         * Function to update the theme based on the provided options.
         * @param opts Options which contain the values to update
         */
        update(opts: { colors?: Colors; appearance?: Appearance }, rootState): void {
            const selected = opts?.appearance ?? rootState.theme.appearance.selected;
            const calculated = getAppearance(selected);

            dispatch.theme.set({
                colors: opts.colors ?? rootState.theme.colors,
                appearance: {
                    selected,
                    calculated,
                },
            });
        },
    }),
});
