import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const PageScroll = (): null => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            setTimeout(() => {
                const el = document.querySelector(location.hash);
                if (!el) return;
                let headerHeight = 0;
                const header = document.querySelector('header');
                if (header) {
                    headerHeight = header.getBoundingClientRect().height;
                }
                const top = (el as HTMLElement).getBoundingClientRect().top;
                window.scrollTo({
                    top: top + window.scrollY - headerHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            });
        } else {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [location]);

    return null;
};
