import { useEffect, useRef } from 'react';

/**
 * Hook which calls the callback when the user clicks anywhere besides the reffered element.
 * @param callback Callback which is called when the scroll position changes
 * @returns Ref which should be attached to the element
 */
export const useClickOutside = <T>(callback: () => void) => {
    const ref = useRef<T>(null);

    const handleClick = (e: MouseEvent) => {
        if (ref.current && !(ref.current as unknown as HTMLElement).contains(e.target as Node)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick, true);
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    return ref;
};
