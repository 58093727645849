import { Footer } from './footer';
import { Header } from './header';

interface Props {
    children?: JSX.Element | JSX.Element[];
}

export const Page = ({ children }: Props) => {
    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    );
};
