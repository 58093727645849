import { useEffect, useState } from 'react';

interface WindowSize {
    width?: number;
    height?: number;
}

/**
 * Hook which returns the current window size and updates it when the window is resized.
 * @returns The current window size
 */
export function useWindowSize(): WindowSize {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleResize = (): void => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize, { passive: true });
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
}
