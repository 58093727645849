import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { languageKeys } from '@/i18n';
import { store, useAppDispatch } from '@/store';

/**
 * Hook which set's the locale for the user and navigates to the root path.
 * @param param0 the locale from the URL
 */
export function SetLocaleHook({ locale }: { locale: string }) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch.language.update({ locale });
        navigate(window.location.pathname.replace(new RegExp(`/${locale}`), '') || '/');
    }, [dispatch.language, navigate, locale]);

    return null;
}

/**
 * Function to set the locale and reload the page.
 * @param locale The locale to set (e.g. en_US)
 */
export function setLocale(locale: string) {
    const lang = getLocale(locale);
    store.dispatch.language.update({ locale: lang });
    window.location.reload();
}

/**
 * Function to check and correct the locale from the given locale.
 * @param locale The locale to get (e.g. en_US)
 * @returns a valid locale (e.g. en_US)
 */
export function getLocale(locale: string): string {
    if (languageKeys.includes(locale)) return locale;

    let loc = 'en_US';

    if (locale.length === 5) {
        const sliced = locale.slice(0, 2);
        for (const k of languageKeys) {
            if (k.startsWith(sliced)) loc = k;
        }
    } else {
        for (const k of languageKeys) {
            if (k.startsWith(locale)) loc = k;
        }
    }

    return loc;
}
