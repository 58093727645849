import { createGlobalStyle, css } from 'styled-components';

import { reset } from './reset';

// NOTE: background-color transition for theme switch
export const AppStyles = createGlobalStyle<{ $disableAnimations?: boolean }>`
    ${reset}

    * {
        font-family: ${(p) => p.theme.text.Manrope.family}Variable;
        color: ${(p) => p.theme.color.gray1};
        line-height: 1.3;
        letter-spacing: .02em;
        ${(p) =>
            p.$disableAnimations
                ? css`
                      animation-delay: 0s !important;
                      animation-duration: 0s !important;
                      transition-delay: 0s !important;
                      transition-duration: 0s !important;

                      ::after,
                      ::before {
                          animation-delay: 0s !important;
                          animation-duration: 0s !important;
                          transition-delay: 0s !important;
                          transition-duration: 0s !important;
                      }
                  `
                : ''}
    }

    pre, kbd, code, samp {
        font-family: ${(p) => p.theme.text.SourceCodePro.family};
    }

    b {
        font-weight: 800;
    }

    *::placeholder {
        font-weight: 400;
        color: ${(p) => p.theme.color.gray3} !important;
    }

    input[type="password"]::placeholder {
        font-weight: 700;
        letter-spacing: .4em;
    }

    #modal {
        z-index: 4000;
    }

    #alert {
        z-index: 5000;
    }

    #pageload[data-loaded] {
        opacity: 0;
        pointer-events: none;
    }

    // Storybook CSS
    #docs-root {
        .docblock-argstable {
            span {
                color: initial;
            }
        }
    }
`;
