import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export function MaintenancePage(): JSX.Element {
    const { t } = useTranslation();

    return (
        <Container>
            <Content>
                <Details>
                    <Title>{t('errors.MAINTENANCE.title')}</Title>
                    <Subtitle>{t('errors.MAINTENANCE.text')}</Subtitle>
                </Details>
                <ImageWrapper>
                    <Image src='/assets/images/fixing.svg' />
                </ImageWrapper>
            </Content>
        </Container>
    );
}

const Container = styled.div`
    ${(p) =>
        p.theme.flex.row({
            justify: 'center',
            fullWidth: true,
            fullHeight: true,
        })}

    min-height: 100vh;
    min-height: -webkit-fill-available;
`;

const Content = styled.div`
    ${(p) => p.theme.flex.row({ justify: 'center', fullHeight: true })}
    max-width: 1000px;

    @media (max-width: 1200px) {
        max-width: 80%;
    }
`;

const Details = styled.div`
    ${(p) => p.theme.flex.col({ grow: true })}
    margin-right: 100px;

    @media screen and (max-width: 900px) {
        padding: 10px;
        align-items: center;
    }

    @media (max-width: 1000px) {
        margin-right: 50px;
    }

    @media screen and (max-width: 900px) {
        margin-right: 0;
    }
`;

const Title = styled.p`
    ${(p) => p.theme.text.Manrope.semibold(40, 'gray1')}
    margin-bottom: 10px;

    @media screen and (max-width: 900px) {
        text-align: center;
    }
`;

const Subtitle = styled.p`
    ${(p) => p.theme.text.Manrope.regular(20, 'gray4')}
    margin-bottom: 40px;

    @media screen and (max-width: 900px) {
        text-align: center;
        margin-bottom: 0;
    }
`;

const ImageWrapper = styled.div`
    @media screen and (max-width: 900px) {
        display: none;
    }
`;

const Image = styled.img`
    min-width: 300px;
    object-fit: contain;
`;
