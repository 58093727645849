import i18next from 'i18next';
import { createModel } from '@rematch/core';

import type { RootModel } from '.';

import { getLocale } from '@/utils/locale';

// eslint-disable-next-line no-unsafe-optional-chaining
export const system = getLocale((navigator.language ?? navigator.languages?.[0]).replace('-', '_'));

interface LanguageState {
    useSystem: boolean;
    locale: string;
    language: string;
}
type LanguageOptions = Partial<LanguageState>;

const INITIAL_STATE: LanguageState = {
    // If the system locale should be used
    useSystem: true,
    // The current locale which is used
    locale: system,
    // The 2 letter language code of the current locale
    language: system.slice(0, 2),
};

export const language = createModel<RootModel>()({
    name: 'language',
    state: INITIAL_STATE,
    reducers: {
        set(state, pl: LanguageOptions) {
            return { ...state, ...pl };
        },
    },
    effects: (dispatch) => ({
        /**
         * Function to update the language state based on the values provided.
         * @param pl Partial language state with values to update
         */
        update(pl: LanguageOptions, rootState) {
            if (!pl.locale && pl.useSystem === undefined) {
                void i18next.changeLanguage(rootState.language.locale);
            } else {
                const locale = pl.locale ? getLocale(pl.locale) : undefined;

                const newState: LanguageOptions = {};
                newState.useSystem = locale ? false : pl.useSystem ?? true;
                newState.locale = newState.useSystem ? system : locale ?? rootState.language.locale;
                newState.language = newState.locale.slice(0, 2);

                void i18next.changeLanguage(newState.locale);

                // TODO: link to fooxly id if not supported

                dispatch.language.set(newState);
            }
        },
    }),
});
