import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';

import { Fade } from '../molecules';

import { MenuIcon, TimesIcon } from '@/icons/platform';

export const Header = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const onNavigate = (link: string) => {
        navigate(link);
        setMobileMenuOpen(false);
    };

    return (
        <>
            <Container>
                <Content>
                    <Logo src='/assets/images/logo.png' />
                    <Links>
                        <MobileMenu onClick={() => setMobileMenuOpen(true)}>
                            <MenuIcon color='blue' size={36} />
                        </MobileMenu>
                        <StyledLink to='/'>{t('common.header.links.home')}</StyledLink>
                        <StyledLink to='/#about-our-business'>{t('common.header.links.services')}</StyledLink>
                        <StyledLink to='/#about-why'>{t('common.header.links.about')}</StyledLink>
                        <StyledLink to='/#contact'>{t('common.header.links.contact')}</StyledLink>
                        <StyledLink to='/#faq'>{t('common.header.links.faq')}</StyledLink>
                    </Links>
                </Content>
            </Container>
            {mobileMenuOpen && (
                <MobileContent>
                    <CloseButton onClick={() => setMobileMenuOpen(false)}>
                        <TimesIcon size={36} color='blue' />
                    </CloseButton>
                    <MobileLinks>
                        <Fade direction='up'>
                            <MobileLink onClick={() => onNavigate('#about-our-business')}>
                                {t('common.header.links.services')}
                            </MobileLink>
                        </Fade>
                        <Fade direction='up' delay={100}>
                            <MobileLink onClick={() => onNavigate('#about-why')}>
                                {t('common.header.links.about')}
                            </MobileLink>
                        </Fade>
                        <Fade direction='up' delay={200}>
                            <MobileLink onClick={() => onNavigate('#contact')}>
                                {t('common.header.links.contact')}
                            </MobileLink>
                        </Fade>
                        <Fade direction='up' delay={300}>
                            <MobileLink onClick={() => onNavigate('#faq')}>
                                {t('common.header.links.faq')}
                            </MobileLink>
                        </Fade>
                    </MobileLinks>
                </MobileContent>
            )}
        </>
    );
};

const Container = styled.div`
    ${(p) => p.theme.flex.col({ align: 'center', justify: 'center' })}
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    padding: 20px;

    @media screen and (max-width: 900px) {
        padding: 15px;
    }
`;

const Content = styled.div`
    ${(p) => p.theme.flex.row({ fullWidth: false, align: 'center', justify: 'space-between' })}
    height: 90px;
    width: 90%;
    max-width: 1800px;
    background-color: ${(p) => p.theme.color.gray10};
    border: 1px solid ${(p) => p.theme.color.gray8};
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: ${(p) => p.theme.shadow.header};

    @media screen and (max-width: 900px) {
        width: 100%;
    }
`;

const Logo = styled.img`
    height: 80%;
    width: auto;
    object-fit: contain;

    @media screen and (max-width: 450px) {
        height: 60%;
    }
`;

const Links = styled.div`
    ${(p) => p.theme.flex.row({ fullWidth: false, align: 'center', justify: 'flex-end' })}
    gap: 30px;
`;

const StyledLink = styled(Link)`
    ${(p) => p.theme.text.Manrope.regular(18, 'blue')}

    transition: color 0.2s ease-in-out;
    will-change: color;

    :hover {
        color: ${(p) => p.theme.color.pink};
    }

    :focus {
        outline: none;
    }

    @media screen and (max-width: 900px) {
        display: none;
    }
`;

const MobileMenu = styled.button`
    display: none;
    background-color: transparent;
    border: none;
    cursor: pointer;

    :focus {
        outline: none;
    }

    @media screen and (max-width: 900px) {
        display: block;
    }
`;

const MobileContent = styled.div`
    position: fixed;
    ${(p) => p.theme.flex.col()}
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: ${(p) => p.theme.color.gray10};
    padding: 40px;
    animation: ${(p) => p.theme.animation.fadeIn} 0.3s ease-in-out;
`;

const CloseButton = styled.button`
    align-self: flex-end;
    margin-bottom: 40px;
    cursor: pointer;

    :focus {
        outline: none;
    }
`;

const MobileLinks = styled.div``;

const MobileLink = styled.button`
    ${(p) => p.theme.text.Manrope.regular(24, 'blue')}
    margin-bottom: 20px;
    background-color: transparent;
    border: none;

    transition: color 0.2s ease-in-out;
    will-change: color;

    :hover {
        color: ${(p) => p.theme.color.pink};
    }

    :focus {
        outline: none;
    }
`;
