import { Pages } from './pages';
import { MaintenancePage } from './pages/maintenance';

import 'react-slideshow-image/dist/styles.css';

export function App() {
    if (JSON.parse(process.env.REACT_APP_UNDER_MAINTENANCE ?? 'false') as boolean) {
        return <MaintenancePage />;
    }

    return <Pages />;
}
