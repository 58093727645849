import styled from 'styled-components';

export const Title = styled.h2`
    ${(p) => p.theme.text.Manrope.medium(55, 'blue')}
    margin-bottom: 50px;

    @media screen and (max-width: 900px) {
        font-size: 45px;
    }

    @media screen and (max-width: 450px) {
        font-size: 35px;
    }
`;
