import { type InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { hexToRgbaString } from '@/utils';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
}

export const Input = ({ label, ...restProps }: Props) => {
    return (
        <Container>
            {!!label && <InputLabel>{label}</InputLabel>}
            <StyledInput {...restProps} />
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    margin-bottom: 30px;
`;

export const InputLabel = styled.p`
    ${(p) => p.theme.text.Manrope.medium(18, 'blue')}
    margin-bottom: 8px;

    @media screen and (max-width: 900px) {
        font-size: 16px;
    }
`;

const StyledInput = styled.input`
    background-color: ${(p) => p.theme.color.gray10};
    ${(p) => p.theme.text.Manrope.regular(16, 'gray1')}
    border: 2px solid ${(p) => hexToRgbaString(p.theme.color.blue, 0.3)};
    border-radius: 8px;
    padding: 8px 12px;
    width: 100%;
    height: 50px;

    will-change: box-shaadow;
    transition: box-shadow 0.2s ease-out;

    :focus {
        box-shadow: 0 0 0 2.5pt ${(p) => hexToRgbaString(p.theme.color.blue, 0.1)};
    }
`;
