import i18nBase, { type TFunction } from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import locales from '@/utils/locales.json';

const envLocales = process.env.REACT_APP_LOCALES?.split(',');

export const languages = locales.filter((locale) => envLocales?.includes(locale.code));
export const languageKeys = languages.map((l) => l.code);

export const i18n = i18nBase
    .use(backend)
    .use(initReactI18next)
    .init({
        lng: envLocales?.[0] ?? 'en_US',
        fallbackLng: envLocales?.[0] ?? 'en_US',
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        },
    });

// export T function
let tf: TFunction = () => '';

// eslint-disable-next-line unicorn/prefer-top-level-await
void i18n.then((t) => {
    tf = t;
    return t;
});

const t: TFunction = tf;

export { t };

export { default as allLanguages } from '@/utils/locales.json';
