import { useMemo } from 'react';
import styled, { keyframes } from 'styled-components';

import type { Color } from '@/types';

interface BaseProps extends React.HTMLAttributes<HTMLOrSVGElement> {
    // Make the progress circle spin forever
    infinite?: boolean;
    // The diameter of the progress circle
    size?: number;
    // The width of the progress bar
    strokeWidth?: number;
    // A possible fill color for the progress bar
    color?: Color;
    // A possible background color behind the progress bar
    backgroundColor?: Color;
}

interface InfiniteProps extends BaseProps {
    // Make the progress circle spin forever
    infinite?: true;
}

interface FiniteProps extends BaseProps {
    // Make sure the progress circle doesn't spin forever
    infinite?: false;
    // A value between 0 and 1
    progress: number;
}

export const ProgressCirlce = ({
    infinite,
    size = 40,
    strokeWidth = 4,
    color = 'blue',
    backgroundColor = 'gray8',
    ...restProps
}: InfiniteProps | FiniteProps) => {
    const isFinite = useMemo(() => !infinite, [infinite]);
    const radius = useMemo(() => size / 2 - strokeWidth * 2, [size, strokeWidth]);
    const circumference = useMemo(() => radius * 2 * Math.PI, [radius]);
    const offset = useMemo(
        () => circumference - Math.min(1, (restProps as FiniteProps)?.progress ?? 0) * circumference,
        [circumference, restProps],
    );

    return (
        <svg {...restProps} width={size} height={size}>
            {!!backgroundColor?.length && (
                <Circle
                    $size={size}
                    $strokeWidth={strokeWidth}
                    $color={backgroundColor}
                    style={{ strokeDashoffset: 1 }}
                />
            )}
            <Circle
                $size={size}
                $strokeWidth={strokeWidth}
                $infinite={infinite}
                $max={isFinite ? 0 : offset}
                $color={color}
                style={{ strokeDashoffset: offset }}
            />
        </svg>
    );
};

const InfiniteAnimation = (max: number) => keyframes`
    0% {
        stroke-dashoffset: ${max * 0.5};
        transform: rotate(-90deg);
    }
    50% {
        stroke-dashoffset: ${max * 0.75};
        transform: rotate(110deg);
    }
    100% {
        stroke-dashoffset: ${max * 0.5};
        transform: rotate(270deg);
    }
`;

interface CircleProps {
    $size: number;
    $strokeWidth: number;
    $color?: Color;
    $infinite?: boolean;
    $max?: number;
}

const Circle = styled.circle.attrs<CircleProps>(({ $size, $strokeWidth }) => ({
    r: $size / 2 - $strokeWidth * 2,
    strokeWidth: $strokeWidth,
    cx: $size / 2,
    cy: $size / 2,
}))<CircleProps>`
    stroke: ${(p) => p.theme.color(p.$color ?? 'blue')};
    fill: transparent;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke-dasharray: ${(p) => (p.$size / 2 - p.$strokeWidth * 2) * 2 * Math.PI};

    animation: ${(p) => InfiniteAnimation(p.$max ?? 0)} 2s linear ${(p) => (p.$infinite ? 'infinite' : '0')};
`;
