import type { Models } from '@rematch/core';

import { theme } from './theme';
import { language } from './language';

export interface RootModel extends Models<RootModel> {
    theme: typeof theme;
    language: typeof language;
}

export const models: RootModel = {
    theme,
    language,
};
