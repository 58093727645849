interface RGB {
    r: number;
    g: number;
    b: number;
}

/**
 * Function to convert a hex color to rgb.
 * @param hex The hex color to convert
 * @returns Object with the rgb values
 */
export function hexToRgb(hex: `#${string}`): RGB | null {
    try {
        let result: RegExpExecArray | null = null;

        if (hex.length === 7) {
            result = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(hex);
        } else if (hex.length === 4) {
            result = /^#?([\da-f])([\da-f])([\da-f])$/i.exec(hex);
            if (!result) throw new Error('INVALID_HEX_COLOR');
            result[1] = `${result[1]}${result[1]}`;
            result[2] = `${result[2]}${result[2]}`;
            result[3] = `${result[3]}${result[3]}`;
        }

        if (result === null) throw new Error('INVALID_HEX_COLOR');

        return {
            r: Number.parseInt(result[1], 16),
            g: Number.parseInt(result[2], 16),
            b: Number.parseInt(result[3], 16),
        };
    } catch {
        return null;
    }
}
