import { init, type RematchDispatch, type RematchRootState } from '@rematch/core';
import { type TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import createRematchPersist from '@rematch/persist';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';

import { models, type RootModel } from './models';

import type { FullModel } from '@/types';

// This key is used to store the persist cache in the browser's local storage
// NOTE: If you change this key, the persist cache will be purged
const PERSIST_KEY = 'vemabo-web';
// NOTE: These models will be persisted, add more models here if needed
const PERSISTENT_MODELS = ['theme', 'language'];

// If the persist cache should be purged
const PURGE_PERSIST =
    process.env.NODE_ENV !== 'production' &&
    (JSON.parse(process.env.REACT_APP_DISABLE_CACHE ?? 'false') as boolean);

export const store = init<RootModel, FullModel>({
    models,
    plugins: [
        // Add persist plugin to persist some models
        createRematchPersist({
            key: PERSIST_KEY,
            storage,
            version: 1,
            whitelist: PURGE_PERSIST ? undefined : PERSISTENT_MODELS,
            blacklist: PURGE_PERSIST ? Object.keys(models) : undefined,
        }),
    ],
    redux: {
        // Add logger middleware if enabled
        middlewares: (JSON.parse(process.env.REACT_APP_ENABLE_LOGGER ?? 'false') as boolean)
            ? [
                  createLogger({
                      collapsed: true,
                  }),
              ]
            : [],
    },
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel & FullModel>;

/**
 * Dispatch hook that is typed to the root state and dispatch.
 */
export const useAppDispatch = (): Dispatch => useDispatch<Dispatch>();
/**
 * Selector hook that is typed to the root state.
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
