/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import qs from 'qs';

type Method = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
type ProgressFunction = (progress: number) => void;
type Server = 'internal';
export interface CallOptions<RT extends RouteOptions> {
    body?: RT['body'];
    bodyType?: 'json' | 'formData' | 'other';
    params?: RT['params'];
    query?: RT['query'];
    signout?: boolean;
    token?: string;
    server?: Server;
    headers?: Record<string, string | undefined>;
    onProgress?: ProgressFunction;
}

async function futch(
    url: string,
    opts: {
        method: Method;
        body: any;
        headers: Record<string, string>;
    },
    onProgress: ProgressFunction,
): Promise<string> {
    const res = await new Promise<string>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(opts.method || 'GET', url);

        for (const k in opts.headers ?? {}) {
            xhr.setRequestHeader(k, opts.headers[k]);
        }

        xhr.addEventListener('error', () => {
            reject(new Error('NETWORK_ERROR'));
        });
        // eslint-disable-next-line unicorn/prefer-add-event-listener
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) resolve(xhr.responseText);
        };

        if (xhr.upload) {
            xhr.upload.addEventListener('progress', (e) => {
                if (!e.lengthComputable) return;
                onProgress((e.loaded / e.total) * 100);
            });
        }

        xhr.send(opts.body as Document);
    });

    return res;
}

export interface RouteOptions {
    body?: any;
    errors?: Record<string, any>;
    params?: Record<string, string | number | boolean>;
    query?: Record<string, string | number | boolean>;
    response?: Record<string, any> | string;
}

function getServerURL(server: Server) {
    // If there are more servers we want to pull data from, we can add them here
    return `${process.env.REACT_APP_API_URL!}`;
}

function getBody(method: Method, options: CallOptions<any>): BodyInit | undefined {
    // rewrite return to not use nested ternary
    if (method === 'GET') return undefined;

    if (options.bodyType === 'json') {
        if (options.body === undefined) return '';

        return JSON.stringify(options.body);
    }

    return options.body as BodyInit;
}

function base(
    method: Method,
    getURL: true,
): <RT extends RouteOptions = any>(path: string, options?: CallOptions<RT>) => string;
function base(
    method: Method,
    getURL?: boolean,
): <RT extends RouteOptions = any>(path: string, options?: CallOptions<RT>) => Promise<RT['response']>;
function base(method: Method, getURL = false) {
    return <RT extends RouteOptions = any>(path: string, options: CallOptions<RT> = {}): any => {
        if (!options.bodyType) options.bodyType = 'json';
        if (!options.server) options.server = 'internal';
        if (!options.headers) options.headers = {};
        if (options.signout === undefined) options.signout = false;

        let url = path.startsWith('http') ? path : `${getServerURL(options.server)}/${path}`;

        if (options.params) {
            for (const [key, value] of Object.entries(options.params)) {
                url = url.replace(new RegExp(`:${key}`, 'gm'), String(value));
            }
        }

        if (options.query) {
            url = `${url}?${qs.stringify(options.query)}`;
        }

        if (getURL) return url;

        if (options.bodyType === 'formData' && options.body !== undefined) {
            const body = new FormData();
            for (const [key, value] of Object.entries(options.body)) {
                if (value !== null && typeof value === 'object' && !(value instanceof File)) {
                    for (const [key2, value2] of Object.entries(value)) {
                        if (value2 !== undefined && value !== null)
                            body.append(`${key}.${key2}`, value2 as string);
                    }
                } else {
                    if (value !== undefined && value !== null) body.append(key, value as string);
                }
            }
            options.body = body;
        }

        const opts = {
            method,
            body: getBody(method, options),
            headers: {
                Accept: 'application/json',
                ...(options.token ? { Authorization: `Bearer ${options.token}` } : {}),
                ...(options.bodyType === 'json' ? { 'Content-Type': 'application/json; charset=utf-8' } : {}),
                ...(options.bodyType === 'formData' ? { 'Content-Type': 'multipart/form-data' } : {}),
                ...options.headers,
            },
        };

        return new Promise((resolve, reject) => {
            // TODO: add timeout when body is not a file upload
            try {
                const logError = (message: string, response?: any): void => {
                    console.error(method, url, message, { response, options: opts });
                };

                if (options.onProgress) {
                    futch(url, opts, options.onProgress)
                        .then((data) => resolve(data as RT['response']))
                        .catch(reject);
                    return;
                }

                fetch(url, opts)
                    .then((res) => {
                        if (res.ok && res.status >= 200 && res.status <= 207) {
                            res.json()
                                .then((body) => {
                                    resolve(body);
                                })
                                .catch(() => {
                                    logError('INVALID_RESPONSE', res);
                                    reject(new Error('INVALID_RESPONSE'));
                                });
                            return;
                        }

                        res.json()
                            .then((body) => {
                                logError((body?.error as string) ?? 'INVALID_RESPONSE', body);
                                // eslint-disable-next-line prefer-promise-reject-errors
                                reject({ message: body?.error ?? 'INVALID_RESPONSE', body });
                                // reject(new Error(body?.error || 'INVALID_RESPONSE'))
                            })
                            .catch(() => {
                                logError('INVALID_RESPONSE', res);
                                reject(new Error('INVALID_RESPONSE'));
                            });
                    })
                    .catch((error) => {
                        logError('NETWORK_ERROR', error);
                        reject(new Error('NETWORK_ERROR'));
                    });
            } catch {
                reject(new Error('UNKNOWN_ERROR'));
            }
        });
    };
}

export const call = {
    get: base('GET'),
    getURL: base('GET', true),
    post: base('POST'),
    patch: base('PATCH'),
    put: base('PUT'),
    del: base('DELETE'),
};
