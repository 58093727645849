import type React from 'react';
import { useLayoutEffect } from 'react';

/**
 * Hook which calls the callback when the scroll position changes.
 * @param container The DOM element to listen scroll to
 * @param callback Callback which is called when the scroll position changes
 * @param dependencies Dependencies for the useLayoutEffect hook
 */
export function useScrollY(
    container: React.RefObject<HTMLElement | null>,
    callback: (scrollY: number) => void,
    dependencies: any[] = [],
): void {
    useLayoutEffect(() => {
        if (!container.current) return;

        let requestRunning: number | undefined;
        const handleScroll = () => {
            if (requestRunning === undefined) {
                requestRunning = window.requestAnimationFrame(() => {
                    if (container.current) {
                        callback(container.current.scrollTop);
                    }
                    requestRunning = undefined;
                });
            }
        };

        container.current.addEventListener('scroll', handleScroll, { passive: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => container.current?.removeEventListener('scroll', handleScroll);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react-hooks/exhaustive-deps
    }, [container, ...dependencies]);
}
