import { lazily } from 'react-lazily';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { languageKeys } from '@/i18n';
import { SetLocaleHook } from '@/utils';
import { PageScroll } from '@/components';

const routes = [
    // Main
    {
        path: '/',
        element: lazily(async () => await import('./home')).HomePage,
        aliases: ['/home'],
    },
    {
        path: '/privacy',
        element: lazily(async () => await import('./privacy-policy')).PrivacyPolicyPage,
        aliases: ['/privacy-policy'],
    },
    {
        path: '*',
        element: lazily(async () => await import('./404')).NotFoundPage,
    },
];

export function Pages(): JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
                {[...languageKeys, ...languageKeys.map((l) => l.slice(0, 2))].map((locale) => (
                    <Route key={locale} path={`/${locale}/*`} element={<SetLocaleHook locale={locale} />} />
                ))}

                {routes
                    .map((route) => [
                        <Route key={route.path} path={route.path} element={<route.element />} />,
                        route.aliases?.map((alias) => (
                            <Route key={alias} path={alias} element={<Navigate to={route.path} />} />
                        )) ?? [],
                    ])
                    .flat(2)}
            </Routes>
            <PageScroll />
        </BrowserRouter>
    );
}
