import styled from 'styled-components';

import { type Color } from '@/types';

interface Props {
    children?: React.ReactNode;
    background?: Color;
    usePadding?: boolean;
    navLink?: string;
}

export const Section = ({ children, background, usePadding = false, navLink }: Props) => {
    return (
        <Container $background={background} $usePadding={usePadding}>
            {!!navLink && <NavLink id={navLink} />}
            <Content>{children}</Content>
        </Container>
    );
};

const Container = styled.div<{ $background?: Color; $usePadding?: boolean }>`
    ${(p) => p.theme.flex.col({ align: 'flex-start', justify: 'center' })}
    position: relative;
    background-color: ${(p) => p.theme.color(p.$background ?? 'gray10')};
    height: ${(p) => (p.$usePadding ? 'auto' : '800px')};
    padding: ${(p) => (p.$usePadding ? '120px 0' : '0')};
    overflow: hidden;
    @media screen and (max-width: 900px) {
        height: auto;
        padding: 60px 0;
    }
`;

const NavLink = styled.a`
    position: absolute;
    top: -60px;
    left: 0;
    opacity: 0;
`;

const Content = styled.div`
    ${(p) => p.theme.flex.col({ align: 'flex-start', justify: 'center' })}
    width: 90%;
    max-width: 1800px;
    align-self: center;
`;
