import { css, type FlattenInterpolation, type ThemeProps } from 'styled-components';

import type { Color, Theme } from '@/types';

import '@fontsource/manrope/variable.css';

import '@fontsource/source-code-pro/variable.css';

const VARIABLE_FONTS = new Set(['Manrope', 'SourceCodePro']);

const weights = {
    Manrope: {
        extralight: 200,
        light: 300,
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extrabold: 800,
    },
    SourceCodePro: {
        extralight: 200,
        light: 300,
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        black: 900,
    },
};
type Weights = typeof weights;

export type FontFamily = keyof Weights;
export type FontWeight<FF extends FontFamily> = keyof Weights[FF];
export type TextAlign = 'left' | 'center' | 'right' | 'inherit';

type TextFunction = ((
    /** Font Size (eg.: 17 or 22) */
    size: number,
    /** Text Color (eg.: 'gray1' or 'blue') */
    color?: Color | null,
    /** Text Alignment (eg.: 'left' | 'center' | 'right' | 'inherit') */
    alignment?: TextAlign,
) => FlattenInterpolation<ThemeProps<Theme>>) & { weight: number };

type Font<FF extends FontFamily> = { family: string } & Record<keyof Weights[FF], TextFunction>;

export const text = (Object.entries(weights) as Array<[FontFamily, Weights[FontFamily]]>).reduce<{
    [FF in FontFamily]: Font<FF>;
}>(
    (acc, [ff, weight]) => {
        return {
            ...acc,
            [ff]: (Object.keys(weight) as Array<FontWeight<typeof ff>>).reduce<Font<typeof ff>>((acc, w) => {
                const func: TextFunction = (size, rawColor, alignment = 'inherit') => {
                    const color = rawColor ?? 'inherit';
                    return css`
                        font-weight: ${weight[w]};
                        font-family: ${ff}${VARIABLE_FONTS.has(ff) ? 'Variable' : ''};
                        ${`font-size: ${size.toString()}px;`}
                        ${(p) => (color === 'inherit' ? '' : `color: ${p.theme.color(color)};`)};
                        ${alignment === 'inherit' ? '' : `text-align: ${alignment};`};
                    `;
                };

                func.weight = weight[w];

                return { ...acc, family: ff, [w]: func };
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/prefer-reduce-type-parameter
            }, {} as any),
        };
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/prefer-reduce-type-parameter
    {} as any,
);
