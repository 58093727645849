import { base } from './base';
import { dark } from './dark';

// All the supported themes
export const themes = {
    base: {
        light: base,
        dark,
    },
    // Add more themes here
    // myTheme: {
    //     light: myThemeLight,
    //     dark: myThemeDark,
    // },
};
