import { hexToRgb } from './hex-to-rgb';

/**
 * Function to convert a hex color to a rgba string.
 * @param hex The hex color to convert
 * @param opacity The opacity of the color
 * @returns A rgba string
 */
export function hexToRgbaString(hex: `#${string}`, opacity = 1): string {
    const rgb = hexToRgb(hex);

    if (!rgb) return '';

    return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity > 1 ? opacity / 100 : opacity})`;
}
